import { http } from "../../config/http"
import { changeLoading } from "./loading.action"
import { changeNotify } from "./notify.action"
import { changeAlert } from "./alert.action"
import { _config } from "../../config/paths"
import Assigned from "../../utils/Assigned"

export const actionTypes = {
  INDEX: "AFFILIATE_INDEX",
  SHOW: "AFFILIATE_SHOW",
  DESTROY: "AFFILIATE_DESTROY",
  CHANGE: "AFFILIATE_CHANGE",
  SUCCESS: "AFFILIATE_SUCCESS",
  ERROR: "AFFILIATE_ERROR",
};

export const change = (payLoad) => ({
  type: actionTypes.CHANGE,
  payLoad,
});

export const success = (payLoad) => ({
  type: actionTypes.SUCCESS,
  payLoad,
});

export const error = (payLoad) => ({
  type: actionTypes.ERROR,
  payLoad,
});

//INDEX
export const indexResponse = (payLoad) => ({
  type: actionTypes.INDEX,
  payLoad,
});

export const index = () => (dispatch) => {
  return http.get(_config.account.api.affiliate.index)
    .then(function (res) {      
      if (Assigned(res)) {
        if (res.request.status === 200) {
          dispatch(indexResponse(res.data.data));
          return res.data.data;
        }
      }
    })
    .catch((error) => {
      // Error
      if (error.response) {
        //A solicitação foi feita e o servidor respondeu com um código de status fora do intervalo 2XX
      } else if (error.request) {
        //  A solicitação foi feita, mas nenhuma resposta foi recebida
        //console.log(error.request);
      } else {
        // Algo aconteceu na configuração da solicitação que acionou um erro
        //console.log("Error", error.message);
      }
    });
};

//STORE
export const store = (model) => (dispatch) => {
  const response = http
    .post(_config.account.api.affiliate.insert, {
      name: model["name"],
      cnpj: model["cnpj"],
      cpf: model["cpf"],
      active: model["active"],
      master: model["master"],
      primaryColor: model["primaryColor"],
      secondaryColor: model["secondaryColor"],
      tertiaryColor: model["tertiaryColor"],
      contactEmail: model["contactEmail"],
      contactPhone: model["contactPhone"],
      businessContactEmail: model["businessContactEmail"],
      businessContactPhone: model["businessContactPhone"],
    })
    .then((res) => {
      if (Assigned(res)) {
        if (res.status === 200 || res.status === 201) {
          dispatch(
            changeNotify({
              open: true,
              class: "success",
              msg: model["name"] + " cadastrado com sucesso",
            })
          );
          dispatch(index());
        }
      }
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 404 || error.response.status === 400) {
          dispatch(
            changeLoading({
              open: false,
            })
          );
          dispatch(
            changeNotify({
              open: true,
              class: "error",
              msg: "Erro ao cadastrar o afiliado",
            })
          );
        }
      } else if (error.request) {
        //  A solicitação foi feita, mas nenhuma resposta foi recebida
        dispatch(
          changeLoading({
            open: false,
          })
        );
        dispatch(
          changeAlert({
            open: true,
            class: "error",
            msg: "Sem resposta do servidor",
          })
        );
        dispatch(index());

      } else {
        // Algo aconteceu na configuração da solicitação que acionou um erro
        //console.log("Error", error.message);
      }
      // console.log(error.config);
    });

  return response
};

//UPDATE
export const update = (data) => (dispatch) => {
  dispatch(changeLoading({ open: true, msg: "Alterando..." }))

  return http.put(_config.account.api.affiliate.update.replace("{id}", data.id), data)
    .then((res) => {
      dispatch(
        changeLoading({
          open: false,
        })
      );

      if (Assigned(res)) {
        if (res.status === 200 || res.status === 201) {
          dispatch(
            changeNotify({
              open: true,
              class: "success",
              msg: "Alterado com sucesso",
            })
          );
        }
        dispatch(index());
      }
    })
    .catch((erro) => {
      dispatch(
        changeNotify({
          open: true,
          class: "error",
          msg: "Erro ao conectar no Servidor",
        })
      );
    });
};

//DESTROY
export const destroyResponse = (payLoad) => ({
  type: actionTypes.DESTROY,
  payLoad,
});

export const destroy = (id) => (dispatch) => {
  dispatch(
    changeLoading({
      open: true,
      msg: "Deletando...",
    })
  );
  return http
    .delete(_config.account.api.affiliate.delete.replace("{id}", id))
    .then((res) => {
      if (Assigned(res)) {
        if (res.status === 200) {
          dispatch(
            changeLoading({
              open: false,
            })
          );
          dispatch(
            changeNotify({
              open: true,
              class: "success",
              msg: "Excluido com Sucesso",
            })
          );
          dispatch(destroyResponse(id));
        }
        dispatch(index());
      }
    })
    .catch((error) => {
      // Error
      if (error.response) {
        //A solicitação foi feita e o servidor respondeu com um código de status fora do intervalo 2XX
        if (error.response.status === 404) {
          dispatch(
            changeLoading({
              open: false,
            })
          );
          dispatch(
            changeNotify({
              open: true,
              class: "error",
              msg: "Tipo de veículo não encontrado",
            })
          );
        }
      } else if (error.request) {
        //  A solicitação foi feita, mas nenhuma resposta foi recebida
        dispatch(
          changeLoading({
            open: false,
          })
        );
        dispatch(
          changeAlert({
            open: true,
            class: "error",
            msg: "Sem resposta do servidor",
          })
        );
        dispatch(index());
        //console.log(error.request);
      } else {
        // Algo aconteceu na configuração da solicitação que acionou um erro
        //console.log("Error", error.message);
      }
      // console.log(error.config);
    });
};