import { makeStyles, createStyles } from "@material-ui/core/styles";
import {
  warningColor,
  blackColor,
  hexToRgb,
  whiteColor,
} from "../../assets/utilits";

const drawerWidth = 240;
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
        display: "flex"
      },
      appBar: {
        zIndex: theme.zIndex.drawer + 1
      },
      appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen
        })
      },
      menuButton: {
        color: theme.palette.secondary.main,
        marginLeft: 12,
        marginRight: 36
      },
      menuButtonIconClosed: {
        transition: theme.transitions.create(["transform"], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen
        }),
        transform: "rotate(0deg)"
      },
      menuButtonIconOpen: {
        transition: theme.transitions.create(["transform"], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen
        }),
        transform: "rotate(180deg)"
      },
      hide: {
        display: "none"
      },
      drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: "nowrap"
      },
      drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create("width", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen
        })
      },
      drawerClose: {
        transition: theme.transitions.create("width", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen
        }),
        overflowX: "hidden",
        width: theme.spacing(0),
        [theme.breakpoints.up("sm")]: {
          width: theme.spacing(9)
        }
        
      },
      toolbar: {
        display: "flex",
        alignItems: "center",
        marginTop: theme.spacing(3),
        justifyContent: "flex-end",
        padding: "0 8px",
        ...theme.mixins.toolbar
      },
      content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        [theme.breakpoints.down("sm")]: {
          padding: theme.spacing(3),
          position:"fixed"
        }
      },
      grow: {
        flexGrow: 1
      },
      logo: {
        height: 45,
      },
      textUser: {
        marginRight: 10,
      },
      list: {
        color: "secondary",
        padding: 10,
      },
      listitem: {
        color: blackColor,
        position: "relative",
        display: "flex",
        textDecoration: "none",
        borderRadius: 3,
        "&:hover,&:focus": {
          color: whiteColor,
          backgroundColor: warningColor[3],
          boxShadow:
            "0 12px 20px -10px rgba(" +
            hexToRgb(warningColor[0]) +
            ",.28), 0 4px 20px 0 rgba(" +
            hexToRgb(blackColor) +
            ",.12), 0 7px 8px -5px rgba(" +
            hexToRgb(warningColor[0]) +
            ",.2)",
        },
      },
  })
);

export default useStyles;
