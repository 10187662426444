import React, { Suspense, lazy } from "react";
import { CircularProgress } from "@mui/material";
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import { http } from "./config/http";
import { _config } from "./config/paths";
import Cookies from "universal-cookie";

const cookie = new Cookies();

const Login = lazy(() => import("./pages/auth"));
const Home = lazy(() => import("./pages/home"));
const Affiliates = lazy(() => import("./pages/affiliates"));
const AffiliatesDomains = lazy(() => import("./pages/affiliateDomain"));
const AffiliatesLogos = lazy(() => import("./pages/affiliateLogo"));
const Groups = lazy(() => import("./pages/groups"));
const GroupEnvironment = lazy(() => import("./pages/groupEnvironment"));
const Companies = lazy(() => import("./pages/company"));
const Products = lazy(() => import("./pages/product"));
const ProductModules = lazy(() => import("./pages/productModule"));
const ProductVariations = lazy(() => import("./pages/productVariation"));
const ProductVariationPrices = lazy(() => import("./pages/productVariationPrice"));
const ProductFunctionalities = lazy(() => import("./pages/productFunctionality"));
const BillingPeriods = lazy(() => import("./pages/billingPeriod"));
const ServicesRoutes = lazy(() => import("./pages/serviceRoute"));
const Licenses = lazy(() => import("./pages/license"));
const Users = lazy(() => import("./pages/user"));
const UsersCompanies = lazy(() => import("./pages/userCompany"));
const UsersLicenses = lazy(() => import("./pages/userLicense"));
const Orders = lazy(() => import("./pages/order"));
const OrdersItems = lazy(() => import("./pages/orderItem"));
const CurrentUserProfile = lazy(() => import("./pages/currentUserProfile"));
const Policies = lazy(() => import("./pages/policy"));
const PoliciesProducts = lazy(() => import("./pages/policyProduct"));
const ProductRequiredEnv = lazy(() => import("./pages/productRequiredEnv"));
const GroupCompanyEnvironmentPendency = lazy(() => import("./pages/groupEnvironmentPendency"));
const ForgotPassword = lazy(() => import("./pages/forgotPassword"));
const ResetPassword = lazy(() => import("./pages/resetPassword"));

const MapTest = lazy(() => import("./pages/maptest"))

const loginRoute = "/login";

const PrivateRoute = ({ children, redirectTo }) => {
  if (!cookie.get("access_token")){
    cookie.remove("access_token");
    window.location = redirectTo;
  }
  else
  {
    http.get(_config.account.api.authenticated)
    .then((response) => 
    {
      if(response.status !== 200)
      { 
        window.location = redirectTo;
      }
    })
    .catch(error => {
      cookie.remove("access_token");
      window.location = redirectTo;
    })
  }
  return children;
};

export const breadcrumbRoutes = [
  { path: "/", breadcrumb: 'Principal', private: true, element: <Home /> },
  { path: "/login", breadcrumb: 'Login', private: false, element:  <Login />},
  { path: "/home", breadcrumb: 'Principal', private: true, element: <Home /> },
  { path: "/affiliates", breadcrumb: 'Afiliados', private: true, element: <Affiliates /> },
  { path: "/affiliates/:affiliateId/domains", breadcrumb: 'Domínios', private: true, element: <AffiliatesDomains /> },
  { path: "/affiliates/:affiliateId/logos", breadcrumb: 'Logotipos', private: true, element: <AffiliatesLogos /> },
  { path: "/affiliates/:affiliateId/billingPeriods", breadcrumb: 'Períodos de Cobrança', private: true, element: <BillingPeriods /> },
  { path: "/groups", breadcrumb: 'Redes', private: true, element: <Groups /> },
  { path: "/groups/:groupId/companies", breadcrumb: 'Empresas', private: true, element: <Companies /> },
  { path: "/groups/:groupId/environments", breadcrumb: 'Ambientes', private: true, element: <GroupEnvironment /> },
  { path: "/products", breadcrumb: 'Produtos', private: true, element: <Products /> },
  { path: "/products/:productId/modules", breadcrumb: 'Módulos', private: true, element: <ProductModules /> },
  { path: "/products/:productId/productVariations", breadcrumb: 'Variações', private: true, element: <ProductVariations /> },
  { path: "/products/:productId/productVariations/:productVariationId/prices", breadcrumb: 'Preços', private: true, element: <ProductVariationPrices /> },  
  { path: "/products/:productId/modules/:productModuleId/productFunctionalities", breadcrumb: 'Funcionalidades', private: true, element: <ProductFunctionalities /> },
  { path: "/servicesRoutes", breadcrumb: 'Rotas de Serviços', private: true, element: <ServicesRoutes /> },  
  { path: "/licenses", breadcrumb: 'Licenças de Usuário', private: true, element: <Licenses /> },    
  { path: "/users", breadcrumb: 'Usuários', private: true, element: <Users /> },  
  { path: "/users/:userId/licenses", breadcrumb: 'Licenças do Usuário', private: true, element: <UsersLicenses /> },  
  { path: "/currentUserProfile", breadcrumb: 'Perfil do Usuário', private: true, element: <CurrentUserProfile /> },  
  { path: "/orders", breadcrumb: 'Pedidos', private: true, element: <Orders /> },  
  { path: "/orders/:orderId/items", breadcrumb: 'Itens do Pedido', private: true, element: <OrdersItems /> },  
  { path: "/policies", breadcrumb: 'Políticas', private: true, element: <Policies /> },  
  { path: "/policies/:policyId/products", breadcrumb: 'Políticas dos produtos', private: true, element: <Policies /> },  
  { path: "/products/:productId/productsRequired", breadcrumb: 'Produtos requeridos', private: true, element: <ProductRequiredEnv /> },
  { path: "/groups/:groupId/environmentHistory", breadcrumb: 'Histórico de ambientes', private: true, element: <GroupCompanyEnvironmentPendency /> },
  { path: "/forgotPassword", breadcrumb: '', private: false, element: <ForgotPassword /> },
  { path: "/resetPassword", breadcrumb: '', private: false, element: <ResetPassword /> },
  

  //{ path: "/users/:userId/companies", breadcrumb: 'Acesso às Empresas', private: true, element: <UsersCompanies /> },  
  
  
];

// const routeLoop = (routes, redirectLoginRoute) => {
//   let result;
//   let finalElement;

//   routes.forEach(route => {
//     finalElement = route.element;

//     if (route.private)
//     {
//       finalElement = (<PrivateRoute redirectTo={ redirectLoginRoute }>{ finalElement }</PrivateRoute> );
//     }

//     result = (<Route path={ route.path } element={ finalElement } />);    
//   });

//   return result;
// };

const Rotas = () => (
  <Router>
    <Suspense
      fallback={
        <div>
          {" "}
          <CircularProgress color="secondary" />{" "}
        </div>
      }
    >
      <Routes>
        <Route path="*" element={<Login />} />
        <Route path="/" element={ <PrivateRoute redirectTo={ loginRoute } > <Home /> </PrivateRoute>} breadcrumb="Principal"/>
        <Route path="/login" element={<Login />} />
        <Route path="/home" element={ <PrivateRoute redirectTo={ loginRoute } ><Home /></PrivateRoute> } />        
        <Route path="/affiliates" element={ <PrivateRoute redirectTo={ loginRoute } ><Affiliates /></PrivateRoute> } />
        <Route path="/affiliates/:affiliateId/domains" element={ <PrivateRoute redirectTo={ loginRoute  }><AffiliatesDomains /></PrivateRoute> } />
        <Route path="/affiliates/:affiliateId/logos" element={ <PrivateRoute redirectTo={ loginRoute  }><AffiliatesLogos /></PrivateRoute> } />
        <Route path="/affiliates/:affiliateId/billingPeriods" element={ <PrivateRoute redirectTo={ loginRoute  }><BillingPeriods /></PrivateRoute> } />
        <Route path="/groups" element={ <PrivateRoute redirectTo={ loginRoute  } ><Groups /></PrivateRoute> } />
        <Route path="/groups/:groupId/companies" element={ <PrivateRoute redirectTo={ loginRoute  } ><Companies /></PrivateRoute> } />
        <Route path="/groups/:groupId/environments" element={ <PrivateRoute redirectTo={ loginRoute  } ><GroupEnvironment /></PrivateRoute> } />
        <Route path="/products" element={ <PrivateRoute redirectTo={ loginRoute  }><Products /></PrivateRoute> } />
        <Route path="/products/:productId/modules" element={ <PrivateRoute redirectTo={ loginRoute  }><ProductModules /></PrivateRoute> } />
        <Route path="/products/:productId/productVariations" element={ <PrivateRoute redirectTo={ loginRoute  }><ProductVariations /></PrivateRoute> } />
        <Route path="/products/:productId/productVariations/:productVariationId/prices" element={ <PrivateRoute redirectTo={ loginRoute  }><ProductVariationPrices /></PrivateRoute> } />
        <Route path="/products/:productId/modules/:productModuleId/productFunctionalities" element={ <PrivateRoute redirectTo={ loginRoute  }><ProductFunctionalities /></PrivateRoute> } />
        <Route path="/servicesRoutes" element={ <PrivateRoute redirectTo={ loginRoute  }><ServicesRoutes /></PrivateRoute> } />
        <Route path="/licenses" element={ <PrivateRoute redirectTo={ loginRoute  }><Licenses /></PrivateRoute> } />        
        <Route path="/users" element={ <PrivateRoute redirectTo={ loginRoute  }><Users /></PrivateRoute> } />
        <Route path="/users/:userId/companies" element={ <PrivateRoute redirectTo={ loginRoute  }><UsersCompanies /></PrivateRoute>} />
        <Route path="/users/:userId/licenses" element={ <PrivateRoute redirectTo={ loginRoute  }><UsersLicenses /></PrivateRoute>} />
        <Route path="/currentUserProfile" element={ <PrivateRoute redirectTo={ loginRoute  }><CurrentUserProfile /></PrivateRoute>} />
        <Route path="/orders" element={ <PrivateRoute redirectTo={ loginRoute  }><Orders /></PrivateRoute>} />
        <Route path="/orders/:orderId/items" element={ <PrivateRoute redirectTo={ loginRoute  }><OrdersItems /></PrivateRoute>} />
        <Route path="/policies" element={ <PrivateRoute redirectTo={ loginRoute  }><Policies /></PrivateRoute>} />
        <Route path="/policies/:policyId/products" element={ <PrivateRoute redirectTo={ loginRoute  }><PoliciesProducts /></PrivateRoute>} />
        <Route path="/products/:productId/productsRequired" element={ <PrivateRoute redirectTo={ loginRoute  }><ProductRequiredEnv /></PrivateRoute>} />
        <Route path="/groups/:groupId/environmentHistory" element={ <PrivateRoute redirectTo={ loginRoute  }><GroupCompanyEnvironmentPendency /></PrivateRoute>} />
        <Route path="/forgotPassword" element={ <ForgotPassword /> } />
        <Route path="/resetPassword" element={ <ResetPassword /> } />

        <Route path="/map" element={ <PrivateRoute redirectTo={ loginRoute  }><MapTest /></PrivateRoute> } />
      </Routes>
    </Suspense>
  </Router>
);

export default Rotas;

