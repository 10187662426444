import {combineReducers} from 'redux';
import authReducer from './auth.reducer';
import loadingReducer from './loading.reducer';
import notifyReducer from './notify.reducer';
import alertReducer from './alert.reducer';
import registerReducer from './register.reducer';
//import { connectRouter } from 'connected-react-router'
import affiliateReducer from './affiliate.reducer';
import affiliateDomainReducer from './affiliateDomain.reducer';
import affiliateLogoReducer from './affiliateLogo.reducer';
import groupReducer from './group.reducer';
import groupEnvironmentReducer from './default.reducer';
import companyReducer from './company.reducer';
import productReducer from './product.reducer';
import productModuleReducer from './productModule.reducer';
import productVariationReducer from './productVariation.reducer';
import productVariationPriceReducer from './default.reducer';
import productFunctionalityReducer from './productFunctionality.reducer';
import affiliateBillingPeriodReducer from './affiliateBillingPeriod.reducer';
import serviceRouteReducer from './default.reducer';
import userReducer from './default.reducer';
import userCompanyReducer from './default.reducer';
import licenseReducer from './default.reducer';
import userLicenseReducer from './default.reducer';
import orderReducer from './default.reducer';
import orderItemReducer from './default.reducer';
import currentUserProfileReducer from './default.reducer';
import policyReducer from './default.reducer';
import productPolicyReducer from './default.reducer';
import productRequiredEnvReducer from './default.reducer';
import groupCompanyEnvironmentPendency from './default.reducer';

const rootReducer = combineReducers({
    authReducer,
    registerReducer,
    loadingReducer,
    notifyReducer,
    alertReducer,
    affiliateReducer,
    affiliateDomainReducer,
    groupReducer,
    groupEnvironmentReducer,
    companyReducer,
    productReducer,
    productModuleReducer,
    productVariationReducer,
    productVariationPriceReducer,
    productFunctionalityReducer,
    affiliateLogoReducer,
    affiliateBillingPeriodReducer,
    serviceRouteReducer,
    userReducer,
    userCompanyReducer,
    licenseReducer,
    userLicenseReducer,
    orderReducer,
    orderItemReducer,
    currentUserProfileReducer,
    policyReducer,
    productPolicyReducer,
    productRequiredEnvReducer,
    groupCompanyEnvironmentPendency,
})

export default rootReducer