import Axios from "axios";
//import { rootUrl, apiaccount, apiCore } from "./app";
import { _config } from "./paths";
import { Decrypt } from "../utils/Encript"; 
import Cookies from "universal-cookie"

const cookie = new Cookies()

//const tk = Decrypt(localStorage.getItem("access_token"));
const tk = Decrypt(cookie.get("access_token"));

export const httpAccount = Axios.create({
  //baseURL: apiaccount,
  baseURL: _config.account.baseUri
});

export const http = Axios.create({
  //baseURL: rootUrl,
  baseURL: _config.account.baseUri
});

http.defaults.headers.authorization = `Bearer ${tk}`;

export const httpCore = Axios.create({
  //baseURL: apiCore,
  baseURL: _config.core.baseUri
});

httpCore.defaults.headers.authorization = `Bearer ${tk}`;;

export const HttpUpload = Axios.create({
  //baseURL: "https://homolog.api.accounts.vinhasoft.com.br/",
  baseURL: _config.account.baseUri
});

HttpUpload.defaults.headers.authorization = `Bearer ${tk}`;