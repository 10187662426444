
import CryptoJS from "crypto-js";

export default function Encrypt(senha){
    const key = "mdacs1321"
    const encryp = CryptoJS.AES.encrypt(senha, key).toString();

    return encryp;
}

export function Decrypt(hash){
    const key = "mdacs1321"
    var bytes  = CryptoJS.AES.decrypt(hash || "", key);
    try{
        var originalText = bytes.toString(CryptoJS.enc.Utf8);
        return originalText
    }catch{
        return console.log("CHAVE ERRADA NOOB");
    }
}
