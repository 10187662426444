import { httpAccount } from "../../config/http";
import { changeLoading } from "./loading.action";
import { changeNotify } from "./notify.action";

export const actionTypes = {
  CHANGE: "REGISTER_CHANGE",
  SUCCESS: "REGISTER_SUCCESS",
  ERROR: "REGISTER_ERROR",
};

export const change = (payload) => ({
  type: actionTypes.CHANGE,
  payload,
});

export const success = (payload) => ({
  type: actionTypes.SUCCESS,
  payload,
});

export const error = (payload) => ({
  type: actionTypes.ERROR,
  payload,
});

export const setUserToken = (token) => (dispatch) => {
  localStorage.setItem("access_token", token);
  dispatch(success(true));
};

export const register = (userData) => (dispatch) => {
  dispatch(
    changeLoading({
      open: true,
      msg: "Cadastrando usuário...",
    })
  );
  return httpAccount.post("/api/auth/register", userData)
    .then((res) => {
      dispatch(
        changeLoading({
          open: false,
          msg: "",
        })
      );
      if (typeof res !== "undefined") {
        if (res.status === 201 || res.status === 200) {
          dispatch(
            changeNotify({
              open: true,
              class: "success",
              msg: "Usuário cadastrado com sucesso",
            })
          );
          dispatch(
            change({
              name: "",
              email: "",
              password: "",
            })
          );
        }
      }
    })
    .catch((err) => {
      dispatch(
        changeLoading({
          open: false,
          msg: "",
        })
      );
      if (err.response) {
        dispatch(
          changeNotify({
            open: true,
            class: "error",
            msg: "Erro ao cadastrar usuário",
          })
        );
        dispatch(error(err.response.data.errors));
      }else{
        dispatch(
          changeNotify({
            open: true,
            class: "error",
            msg: "Erro ao conectar com o servidor",
          })
        );
      }
    });
};
