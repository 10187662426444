import { http } from "../../config/http"
import { changeLoading } from "./loading.action"
import { changeNotify } from "./notify.action"
import Assigned from "../../utils/Assigned"
import { successfullyResponse } from "../../utils/responseCodes"

export const actionTypes = {
  INDEX: "INDEX",
  SHOW: "SHOW",
  DESTROY: "DESTROY",
  CHANGE: "CHANGE",
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
}

export const change = (payLoad) => ({ type: actionTypes.CHANGE, payLoad, })
export const success = (payLoad) => ({ type: actionTypes.SUCCESS, payLoad, })
export const error = (payLoad) => ({ type: actionTypes.ERROR, payLoad, })
export const indexResponse = (payLoad) => ({ type: actionTypes.INDEX, payLoad, })
export const destroyResponse = (payLoad) => ({ type: actionTypes.DESTROY, payLoad, })

//Index
export const index = (indexUri) => (dispatch) => {      
    return http.get(indexUri)
    .then(function (response) 
    {      
        if (Assigned(response) && successfullyResponse(response.request.status)) 
        {
            dispatch(indexResponse(response.data.data))
            return response.data.data
        }
    })
    .catch((error) => 
    { 
        dispatch(indexResponse([]))
        changeNotify({ open: true, class: "error", msg: "Ocorreu um erro ao buscar as informações" }) 
    })
}

//Store
export const store = (indexUri, insertUri, insertModel, dispatch) => {
    return http.post(insertUri, insertModel)
    .then(response => {
        if (Assigned(response)) 
        {
            if (Assigned(response) && successfullyResponse(response.request.status))
            {
                dispatch(changeNotify({ open: true, class: "success", msg: "Cadastrado com sucesso", }))
                dispatch(index(indexUri, dispatch))
            }
        }
    })
    .catch((error) => {
        dispatch( changeNotify({ open: true, class: "error", msg: "Erro ao cadastrar um novo registro", }) )
    })
}

//UPDATE
export const update = (indexUri, updateUri, model, dispatch) => {
    dispatch(changeLoading({ open: true, msg: "Alterando..." }))

    return http.put(updateUri.replace("{id}", model.id), model)
    .then((response) => 
    {
        dispatch(changeLoading({ open: false, }) )

        if (Assigned(response) && successfullyResponse(response.status)) 
        {
            dispatch(changeNotify({ open: true, class: "success", msg: "Alterado com sucesso", }))
            dispatch(index(indexUri, dispatch));
        }
    })
    .catch((erro) => {
        dispatch(changeNotify({ open: true, class: "error", msg: "Erro ao conectar no Servidor", })) 
    })
}

//remove
export const remove = (indexUri, deleteUri, id, dispatch) => {
  dispatch( changeLoading({ open: true, msg: "Deletando...", }) )

  return http
    .delete(deleteUri.replace("{id}", id))
    .then((response) => {
        if (Assigned(response) && successfullyResponse(response.status)) 
        {
            dispatch(changeLoading({ open: false, }))
            dispatch(changeNotify({ open: true, class: "success",  msg: "Excluido com Sucesso", }))
            dispatch(destroyResponse(id))
            dispatch(index(indexUri, dispatch))
        }
    })
    .catch((error) => {
        dispatch(changeNotify({ open: true, class: "error", msg: "Ocorreu um erro ao excluir o registro", }))
    })
}

//Custom POST
export const customPost = (uri, model, dispatch, callbackOnSuccess = null) => {
    return http.post(uri, model)
    .then(response => {
        if (Assigned(response)) 
        {
            if (Assigned(response) && successfullyResponse(response.request.status))
            {
                dispatch(changeNotify({ open: true, class: "success", msg: "Sucesso", }));

                if (Assigned(callbackOnSuccess))
                {
                    callbackOnSuccess(response);
                }
            }
        }
    })
    .catch((error) => {
        dispatch( changeNotify({ open: true, class: "error", msg: "Erro", }) )
    })
}

//Custom PUT
export const customPut = (uri, model, dispatch, callbackOnSuccess = null) => {
    return http.put(uri, model)
    .then(response => {
        if (Assigned(response)) 
        {
            if (Assigned(response) && successfullyResponse(response.request.status))
            {
                dispatch(changeNotify({ open: true, class: "success", msg: "Sucesso", }));

                if (Assigned(callbackOnSuccess))
                {
                    callbackOnSuccess(response);
                }
            }
        }
    })
    .catch((error) => {
        dispatch( changeNotify({ open: true, class: "error", msg: "Erro", }) )
    })
}

//UPLOAD 
export const customUpload = (uri, files, dispatch, callbackOnSuccess = null, formDataName = null) => {
    if (Assigned(uri) && Assigned(files))
    {
        const name = "customUploadName";

        if (Assigned(formDataName) && formDataName.trim() !== "")
        {
            name = formDataName;
        };

        const config = {headers: {'content-type': 'multipart/form-data'}};

        const formData = new FormData();
        formData.append("files", files);

        return http.post(uri, formData, config)
        .then(response => {
            if (Assigned(response) && successfullyResponse(response.request.status)) 
            {
                dispatch(changeNotify({ open: true, class: "success", msg: "Sucesso", }));

                if (Assigned(callbackOnSuccess))
                {
                    callbackOnSuccess(response);
                }
            }
        })
        .catch((error) => {
            dispatch( changeNotify({ open: true, class: "error", msg: "Erro", }) )
        })
    }
}


