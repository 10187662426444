import React from "react";
//import { Snackbar, SnackbarContent } from "@material-ui/core";
import { Snackbar, SnackbarContent } from '@mui/material';
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { changeNotify } from "../store/actions/notify.action";
import { Error, CheckCircle } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  success: {
    backgroundColor: "#008000",
    justifyContent: "center",
  },
  error: {
    backgroundColor: "#ff0000",
    justifyContent: "center",
  },
  message: {
    fontSize: 16,
    color: "#ffff",
  },
  iconMessage: {
    fontSize: 18,
    marginRight: 5,
    paddingTop: 3
  }
}));

export default function Notify() {
  const dispatch = useDispatch();
  const notify = useSelector((state) => state.notifyReducer);
  const classes = useStyles();

  return (
    <Snackbar
      anchorOrigin={{ horizontal: notify.horizontal, vertical: notify.vertical, }}
      open={ notify.open }
      autoHideDuration={ notify.time }
      onClose={() => dispatch(changeNotify({ open: false }))}
    >
      <SnackbarContent
        className={classes[notify.class]}
        message={
          <span className={classes.message}>
            {notify.class === "success" && <CheckCircle className={classes.iconMessage}/>}
            {notify.class === "error" && <Error className={classes.iconMessage}/>}
            {notify.msg}
          </span>
        }
      />
    </Snackbar>
  );
}
