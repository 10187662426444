import React from "react";
import classNames from "clsx";
import { AppBar, Toolbar, List } from "@material-ui/core";
import Drawer from "@material-ui/core/Drawer";
//import Drawer from '@mui/material/Drawer';
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import AccountCircle from "@material-ui/icons/AccountCircle";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
//import Menu from "@material-ui/core/Menu";
import Menu from "@mui/material/Menu";
//import MenuItem from "@material-ui/core/MenuItem";
import MenuItem from "@mui/material/MenuItem";
//import Collapse from "@material-ui/core/Collapse";
import Collapse from "@mui/material/Collapse";
import Link from "@material-ui/core/Link";
import useStyles from "./styled";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ForwardIcon from '@material-ui/icons/Forward';
import Assigned from "../../utils/Assigned";
import VSProfileCard from "../../components/VSProfileCard"

export default function MiniDrawer(props) {
  const classes = useStyles();
  // const theme = useTheme();
  // const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false)
  const [menuUser, setMenuUser] = React.useState(false)
  const [menuItemParentOpenedStatusKey, setMenuItemParentOpenStatus] = React.useState([null])

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setMenuItemParentOpenStatus(null)
    setOpen(false);
  };

  const handleMenuUser = (event) => {
    setMenuUser(event.currentTarget);
  };

  const handleMenuItemParentClick = (key) => {
    if (menuItemParentOpenedStatusKey === key)
    {
      //Fechar
      setMenuItemParentOpenStatus(null)
    }
    else
    {
      //Abrir
      setMenuItemParentOpenStatus(key)
    }
    handleDrawerOpen(true)
  }

  const createLink = (href, underline, element, id = null) => {
    if (Assigned(id))
    {
      return <Link key={ id } id={"link-" + id } href={ href } underline={ underline }>{ element }</Link>
    }
    else
    {
      return <Link key={ id } id={"linkr-" + (Math.random() * 10000) } href={ href } underline={ underline }>{ element }</Link>
    }
  }

  const createMenuItem = (key, itemClassName, icon, text, itemHasSubItems, onclick) => {
    return <ListItem className={ classes.listitem } button onClick={ onclick }>
              { !Assigned(icon) ? <ListItemIcon><ForwardIcon/></ListItemIcon> : <ListItemIcon>{ icon }</ListItemIcon> }
              <ListItemText primary= { text } />
              { itemHasSubItems ? key === menuItemParentOpenedStatusKey ? <ExpandMore /> : <ExpandLess /> : null }
            </ListItem>
  }

  const itemsElement = []  

  props.items.forEach((item, itemIndex) => {
    if (Assigned(item) && Assigned(item.type)){    
      
      let mustShow = true;

      if (Assigned(item.data) && Assigned(item.data.validation))
      {
        mustShow = item.data.validation();
      } 

      if (mustShow)
      {      
        switch (item.type){
          case "divider": {
            itemsElement.push(<Divider key={"divider-" + itemIndex }/>)
            break
          }
          case "item":  {
            if (Assigned(item.data)) {
              let itemHasSubItems = Assigned(item.data.subItems) && item.data.subItems.length > 0

              let menuItemParentOnClick = item.data.onclick

              if (itemHasSubItems)
              {
                menuItemParentOnClick = function(element)
                {
                  handleMenuItemParentClick(itemIndex);

                  if (Assigned(item.data.onclick)){
                    item.data.onclick(element);
                  }
                }
              }            

              let onClickExists = Assigned(menuItemParentOnClick)

              let parentItem = (
                <List id={"list-" + itemIndex} className={ classes.list }>
                  { onClickExists ? 
                      //createMenuItem(item.data.itemClassName, item.data.icon, item.data.text, itemHasSubItems, item.data.onclick) 
                      createLink(item.data.href, "none", createMenuItem(itemIndex, item.data.itemClassName, item.data.icon, item.data.text, itemHasSubItems, menuItemParentOnClick))
                      : 
                      createLink(item.data.href, "none", createMenuItem(itemIndex, item.data.itemClassName, item.data.icon, item.data.text, itemHasSubItems, menuItemParentOnClick)) }
                </List>
              )

              let subItem = null
              if (Assigned(item) && Assigned(item.data) && Assigned(item.data.subItems))
              {
                let jsxSubItem = []

                item.data.subItems.forEach((subItemElement, subItemIndex) => {                  
                  jsxSubItem.push(createLink(subItemElement.href, "none", createMenuItem(subItemIndex, subItemElement.itemClassName, subItemElement.icon, subItemElement.text, false, subItemElement.onclick), subItemIndex))
                })

                subItem = (
                  <Collapse id={"collapse-" + itemIndex } in={ itemIndex === menuItemParentOpenedStatusKey } timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      { jsxSubItem } 
                    </List>                            
                  </Collapse>)
              }

              itemsElement.push(
              <div key={ "div-" + itemIndex }>
                { parentItem } 
                { subItem }
              </div>)
            }
            break
          }
          default: {
            break
          }
        }
      }  
    }
  });

  const header = () => {
    return (
      <AppBar
        position="fixed"
        className={classes.appBar}
        foojon={classNames(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar disableGutters={true}>
          {!open === true && (
            <IconButton
              color="secondary"
              aria-label="Open drawer"
              onClick={handleDrawerOpen}
              className={classes.menuButton}
            >
              <MenuIcon
                classes={{
                  root: open
                    ? classes.menuButtonIconOpen
                    : classes.menuButtonIconClosed,
                }}
              />
            </IconButton>
          )}
          {open === true && (
            <IconButton
              color="secondary"
              aria-label="Open drawer"
              onClick={handleDrawerClose}
              className={classes.menuButton}
            >
              <MenuIcon
                classes={{
                  root: open
                    ? classes.menuButtonIconOpen
                    : classes.menuButtonIconClosed,
                }}
              />
            </IconButton>
          )}
          <Typography
            variant="h6"
            color="inherit"
            className={classes.grow}
            noWrap
          >
            <Link href={ Assigned(props) && Assigned(props.headInfo) ? props.headInfo.logoLink : null } underline="none">
              <img
                src= { Assigned(props) && Assigned(props.headInfo) ? props.headInfo.logo : null } 
                alt={ Assigned(props) && Assigned(props.headInfo) ? props.headInfo.altText : null }
                className={classes.logo}
              />
            </Link>
          </Typography>
          <div>
            <IconButton
              aria-owns={open ? "menu-appbar" : undefined}
              aria-haspopup="true"
              onClick={handleMenuUser}
              color="secondary"
            >
              <AccountCircle />
            </IconButton>
            {menuUser && (
              <Menu
                anchorEl={menuUser}
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                open={menuUser}
                onClose={() => setMenuUser(false)}
              >
                <MenuItem>                
                  <VSProfileCard show={ (() => { return true; }) } username={ props.headInfo.profile.username } img={ props.headInfo.profile.img } alt="Foto do perfil" onLogout={ props.headInfo.logout } />
                </MenuItem>
                {/* <MenuItem onClick={() => dispatch(Logout)}>
                  <ExitToApp />
                  Sair
                </MenuItem> */}
              </Menu>
            )}
          </div>
        </Toolbar>
      </AppBar>
    )
  }

  const drawer = () => {
    return (
      <Drawer
        variant="permanent"
        className={classNames(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: classNames({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar} />

        { itemsElement }
        
      </Drawer>
    )
  }

  const mustShowTemplate = (!Assigned(props.show) || props.show)

  return (
    <div className={classes.root}>
      <CssBaseline />

      {mustShowTemplate ? header() : null }
      {mustShowTemplate ? drawer() : null }

      <main className={classes.content}>
        <div className={classes.toolbar} />
        {props.children}
      </main>
    </div>
  )
}