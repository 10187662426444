import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import { ThemeProvider } from "@material-ui/core/styles";
import Home from "@material-ui/icons/Home";
import SecurityIcon from "@material-ui/icons/Security";
import GroupIcon from "@material-ui/icons/Group";
import DeviceHubIcon from "@material-ui/icons/DeviceHub";
import DescriptionIcon from "@material-ui/icons/Description";
import PagesIcon from "@material-ui/icons/Pages";
import { Provider } from "react-redux";
import { store } from "./store/store";
import light from "./themes/light";
import Menu from "./components/MainMenu";
import Rotas from "./routes";
import IsAdmin from "./services/auth";
import Cookies from "universal-cookie";
import { LogoutEx } from "./store/actions/auth.action";
import Notify from "./components/Notify";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import PersonIcon from "@material-ui/icons/Person";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import PolicyIcon from "@material-ui/icons/Policy";
import { _config } from "./config/paths";
import CookieDefs from "./defs/cookieDefs";
import Assigned from "./utils/Assigned";

const cookieDefs = CookieDefs();

const cookie = new Cookies();

function getUsername() {
    return cookie.get(cookieDefs.USER_NAME);
}

function getProfileImage() {
    let userId = cookie.get(cookieDefs.USER_ID);

    if (Assigned(userId) && userId !== "")
    {
        return _config.account.api.userPhoto.avatar.replace("{id}", userId);
    }
    else
    {
        return "https://www.pngitem.com/pimgs/m/146-1468479_my-profile-icon-blank-profile-picture-circle-hd.png";
    }
}

function HandleTemplateShow() {
    const location = window.location;
    const uri = location.href.toLowerCase();

    return !uri.endsWith("/login") && !uri.includes("/resetpassword") && !uri.endsWith("/forgotpassword");
}

function App() {
    //const dispatch = useDispatch()

    const headInfo = {
        logo: "/assets/images/logo.svg",
        logoLink: "/",
        altText: "Vinhasoft",
        profile: {
            username: getUsername(),
            img: getProfileImage(),
        },
        //logout: logout
        logout: LogoutEx,
    };

    const items = [
        {
            type: "item",
            data: {
                text: "Principal",
                icon: <Home />,
                href: "/",
                underline: "none",
                listClassName:
                    "MuiList-root makeStyles-list-16 MuiList-padding",
                itemClassName:
                    "MuiButtonBase-root MuiListItem-root makeStyles-listitem-17 MuiListItem-gutters MuiListItem-button",
                subItems: null,
                onclick: null,
                validation: null,
            },
        },
        {
            type: "divider",
        },
        {
            type: "item",
            data: {
                text: "Administração",
                icon: <SecurityIcon />,
                href: "#",
                underline: "none",
                listClassName:
                    "MuiList-root makeStyles-list-16 MuiList-padding",
                itemClassName:
                    "MuiButtonBase-root MuiListItem-root makeStyles-listitem-17 MuiListItem-gutters MuiListItem-button",
                onclick: null,
                validation: IsAdmin,
                subItems: [
                    {
                        text: "Afiliados",
                        icon: <GroupIcon />,
                        href: "/affiliates",
                        underline: "none",
                        itemClassName:
                            "MuiButtonBase-root MuiListItem-root makeStyles-listitem-17 MuiListItem-gutters MuiListItem-button",
                    },
                    {
                        text: "Redes",
                        icon: <DeviceHubIcon />,
                        href: "/groups",
                        underline: "none",
                        itemClassName:
                            "MuiButtonBase-root MuiListItem-root makeStyles-listitem-17 MuiListItem-gutters MuiListItem-button",
                    },
                    {
                        text: "Produtos",
                        icon: <PagesIcon />,
                        href: "/products",
                        underline: "none",
                        itemClassName:
                            "MuiButtonBase-root MuiListItem-root makeStyles-listitem-17 MuiListItem-gutters MuiListItem-button",
                    },
                    {
                        text: "Rotas de Serviços",
                        icon: <LocationOnIcon />,
                        href: "/servicesRoutes",
                        underline: "none",
                        itemClassName:
                            "MuiButtonBase-root MuiListItem-root makeStyles-listitem-17 MuiListItem-gutters MuiListItem-button",
                    },
                    {
                        text: "Usuários",
                        icon: <PersonIcon />,
                        href: "/users",
                        underline: "none",
                        itemClassName:
                            "MuiButtonBase-root MuiListItem-root makeStyles-listitem-17 MuiListItem-gutters MuiListItem-button",
                    },
                    {
                        text: "Licenças",
                        icon: <VpnKeyIcon />,
                        href: "/licenses",
                        underline: "none",
                        itemClassName:
                            "MuiButtonBase-root MuiListItem-root makeStyles-listitem-17 MuiListItem-gutters MuiListItem-button",
                    },
                    {
                        text: "Pedidos",
                        icon: <DescriptionIcon />,
                        href: "/orders",
                        underline: "none",
                        itemClassName:
                            "MuiButtonBase-root MuiListItem-root makeStyles-listitem-17 MuiListItem-gutters MuiListItem-button",
                    },
                ],
            },
        },
        {
            type: "divider",
            data: {
                validation: IsAdmin,
            },
        },
        {
            type: "item",
            data: {
                text: "Políticas",
                icon: <PolicyIcon />,
                href: "/policies",
                underline: "none",
                listClassName:
                    "MuiList-root makeStyles-list-16 MuiList-padding",
                itemClassName:
                    "MuiButtonBase-root MuiListItem-root makeStyles-listitem-17 MuiListItem-gutters MuiListItem-button",
                subItems: null,
                onclick: null,
                validation: null,
            },
        },
        {
            type: "divider",
        },
    ];

    return (
        <Provider store={store}>
            <ThemeProvider theme={light}>
                <Notify />
                <Menu
                    show={HandleTemplateShow()}
                    headInfo={headInfo}
                    items={items}
                >
                    <Rotas />
                </Menu>
            </ThemeProvider>
        </Provider>
    );
}

export default App;
