import { actionTypes } from "../actions/affiliate.action";

const initialState = {
  affiliates: {
    data: [],
  },
  affiliate: {},
  success: false,
  error: {},
};

// eslint-disable-next-line
export default (state = initialState, { type, payLoad }) => {
  switch (type) {
    case actionTypes.INDEX:
      return {    ...state,
        affiliates: {
          ...state.affiliates,
          data: payLoad,
        },};
    case actionTypes.DESTROY:
      return {
        ...state,
        affiliates: {
          ...state.affiliates,
          data: state.affiliates.data.filter((item) => item.id !== payLoad),
        },
      };
    case actionTypes.CHANGE:
      return {
        ...state,
        affiliate: {
          ...state.affiliate,
          ...payLoad,
        },
      };
    case actionTypes.SUCCESS:
      return { ...state, success: payLoad };
    case actionTypes.ERROR:
      return {
        ...state,
        error: payLoad,
      };
    default:
      return state;
  }
};
