import Assigned from "./Assigned"

export const checkResponseCodes = (code, codesList) =>
{
    if (Assigned(code) && Assigned(codesList) && codesList.length > 0)
    {
        codesList.forEach(element => 
        {
            if (element === code) 
            {
                return true
            }
        })
    }

    return false
}

export const successfullyResponse = (code) => 
{    
    if (Assigned(code))
    {        
        if (code.toString().charAt(0) !== "4" && code.toString().charAt(0) !== "5")
        {
            return true
        }
    }

    return false
}
