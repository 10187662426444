import { http } from "../../config/http";
import { changeLoading } from "./loading.action";
import { changeNotify } from "./notify.action";
import { changeAlert } from "./alert.action";
import { _config } from "../../config/paths";
import Assigned from "../../utils/Assigned";
import { customPost, customPut } from "./default.action";

export const actionTypes = {
  INDEX: "GROUP_INDEX",
  SHOW: "GROUP_SHOW",
  DESTROY: "GROUP_DESTROY",
  CHANGE: "GROUP_CHANGE",
  SUCCESS: "GROUP_SUCCESS",
  ERROR: "GROUP_ERROR",
};

export const change = (payLoad) => ({
  type: actionTypes.CHANGE,
  payLoad,
});

export const success = (payLoad) => ({
  type: actionTypes.SUCCESS,
  payLoad,
});

export const error = (payLoad) => ({
  type: actionTypes.ERROR,
  payLoad,
});

//INDEX
export const indexResponse = (payLoad) => ({
  type: actionTypes.INDEX,
  payLoad,
});

export const index = () => (dispatch) => {
  return http.get(_config.account.api.group.index)
    .then(function (res) {      
      if (Assigned(res)) {
        if (res.request.status === 200) {
          dispatch(indexResponse(res.data.data));
          return res.data.data;
        }
      }
    })
    .catch((error) => {
      // Error
      if (error.response) {
        //A solicitação foi feita e o servidor respondeu com um código de status fora do intervalo 2XX
      } else if (error.request) {
        //  A solicitação foi feita, mas nenhuma resposta foi recebida
      } else {
        // Algo aconteceu na configuração da solicitação que acionou um erro
      }
    });
};

//STORE
export const store = (model) => (dispatch) => {
  const response = http
    .post(_config.account.api.group.insert, {
      affiliateId: model["affiliateId"],
      name: model["name"],
      active: model["active"],      
    })
    .then((res) => {
      if (Assigned(res)) {
        if (res.status === 200 || res.status === 201) {
          dispatch(
            changeNotify({
              open: true,
              class: "success",
              msg: model["name"] + " cadastrado com sucesso",
            })
          );
          dispatch(index());
        }
      }
    })
    .catch((error) => {      
      if (error.response) {
        if (error.response.status === 404 || error.response.status === 400) {
          dispatch(
            changeLoading({
              open: false,
            })
          );
          dispatch(
            changeNotify({
              open: true,
              class: "error",
              msg: "Erro ao cadastrar uma rede",
            })
          );
        }
      } else if (error.request) {
        //  A solicitação foi feita, mas nenhuma resposta foi recebida
        dispatch(
          changeLoading({
            open: false,
          })
        );
        dispatch(
          changeAlert({
            open: true,
            class: "error",
            msg: "Sem resposta do servidor",
          })
        );
        dispatch(index());

      } else {
        // Algo aconteceu na configuração da solicitação que acionou um erro
      }
      // console.log(error.config);
    });
  return response
};

//UPDATE
export const update = (data) => (dispatch) => {
  dispatch(changeLoading({ open: true, msg: "Alterando..." }))

  return http.put(_config.account.api.group.update.replace("{id}", data.id), data)
    .then((res) => {
      dispatch(
        changeLoading({
          open: false,
        })
      );

      if (Assigned(res)) {
        if (res.status === 200 || res.status === 201) {
          dispatch(
            changeNotify({
              open: true,
              class: "success",
              msg: "Alterado com sucesso",
            })
          );
        }
        dispatch(index());
      }
    })
    .catch((erro) => {
      dispatch(
        changeNotify({
          open: true,
          class: "error",
          msg: "Erro ao conectar no Servidor",
        })
      );
    });
};

//DESTROY
export const destroyResponse = (payLoad) => ({
  type: actionTypes.DESTROY,
  payLoad,
});

export const destroy = (id) => (dispatch) => {
  dispatch(
    changeLoading({
      open: true,
      msg: "Deletando...",
    })
  );
  return http
    .delete(_config.account.api.group.delete.replace("{id}", id))
    .then((res) => {
      if (Assigned(res)) {
        if (res.status === 200) {
          dispatch(
            changeLoading({
              open: false,
            })
          );
          dispatch(
            changeNotify({
              open: true,
              class: "success",
              msg: "Excluido com Sucesso",
            })
          );
          dispatch(destroyResponse(id));
        }
        dispatch(index());
      }
    })
    .catch((error) => {
      // Error
      if (error.response) {
        //A solicitação foi feita e o servidor respondeu com um código de status fora do intervalo 2XX
        if (error.response.status === 404) {
          dispatch(
            changeLoading({
              open: false,
            })
          );
          dispatch(
            changeNotify({
              open: true,
              class: "error",
              msg: "Rede não encontrada",
            })
          );
        }
      } else if (error.request) {
        //  A solicitação foi feita, mas nenhuma resposta foi recebida
        dispatch(
          changeLoading({
            open: false,
          })
        );
        dispatch(
          changeAlert({
            open: true,
            class: "error",
            msg: "Sem resposta do servidor",
          })
        );
        dispatch(index());        
      } else {
        // Algo aconteceu na configuração da solicitação que acionou um erro
      }
      // console.log(error.config);
    });
};

//createMissingEnvironment
export const createMissingEnvironment = (model) => (dispatch) => {
  return customPost(_config.account.api.group.createMissingEnvironment,  model,  dispatch, null);
 }

 export const updateEnvironmentStatus = (id) => (dispatch) => {
  return customPut(_config.account.api.group.updateEnvironmentStatus.replace("{id}", id),  null,  dispatch, null);
 }
 