import { actionTypes } from "../actions/group.action"

const initialState = {
  groups: {
    data: [],
  },
  group: {},
  success: false,
  error: {},
}

// eslint-disable-next-line
export default (state = initialState, { type, payLoad }) => {
  switch (type) {
    case actionTypes.INDEX:
      return {    ...state,
        groups: {
          ...state.groups,
          data: payLoad,
        },};
    case actionTypes.DESTROY:
      return {
        ...state,
        groups: {
          ...state.groups,
          data: state.groups.data.filter((item) => item.id !== payLoad),
        },
      };
    case actionTypes.CHANGE:
      return {
        ...state,
        group: {
          ...state.group,
          ...payLoad,
        },
      };
    case actionTypes.SUCCESS:
      return { ...state, success: payLoad };
    case actionTypes.ERROR:
      return {
        ...state,
        error: payLoad,
      };
    default:
      return state;
  }
};
