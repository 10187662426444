import React from "react";
import { Button } from "@material-ui/core";
//import Button from '@material-ui/core/Button';

//import useStyles from "./styled";
import "./style.css";
import { Assigned } from "../../utils/Assigned";

//import { Assigned } from "../../utils/Assigned";

/**
 * Card visual das informações do perfil do usuário.
 *
 * Componente responsável por mostrar as informações do perfil do usuário.
 *
 * @since      1.0.0
 * @access     public
 *
 * @param {type} img URI da imagem do perfil.
 * @param {type} username Nome do usuário.
 * @param {type} onLogout Método a ser executado para o logout.
 * @return {type} Elementos unidos depois das validações individuais realizadas.
 */
export default function VSProfileCard(props) {
    //const classes = useStyles();

    //return (Assigned(props.show) && props.show ? (
    return props.show ? (
        <div
            id="userHeaderInfoProfile"
            class="vs-header-user-card"
            onMouseLeave={props.onMouseLeave}
        >
            <div class="vs-header-user-card-column_one">
                <div class="vs-header-user-card-text">
                    <h6>{props.username}</h6>
                    <div class="vs-header-user-card-line"></div>
                    <div>
                        <p>
                            <a href="/currentUserProfile" class="vs-card-link">Perfil</a>
                        </p>
                    </div>
                </div>
                <div class="vs-header-user-card-footer">
                    <Button
                        color="primary"
                        variant="outlined"
                        size="small"
                        onClick={props.onLogout}
                    >
                        Sair
                    </Button>
                </div>
            </div>
            <div class="vs-header-user-column_two">
                <img src={props.img} alt={props.alt} />
            </div>
        </div>
    ) : null;
}
