import { actionTypes } from "../actions/alert.action";

const INITIAL_STATE = {
  open: false,
  class: 'error',
  time: 3000,
  msg: 'DADOS ATUALIZADOS' 
};

// eslint-disable-next-line
export default (state = INITIAL_STATE, { type, payLoad }) => {
  switch (type) {
    case actionTypes.CHAGE:
      return { ...state, ...payLoad };
    default:
      return state;
  }
};