//Config
const apiSegment = "/api"
const version1Segment = "/v1"

const accountBaseUri = "https://api.accounts.vinhasoft.com.br";
//const accountBaseUri = "https://homolog.api.accounts.vinhasoft.com.br";
//const accountBaseUri = "https://localhost:44328";

const coreBaseUri = "https://api.omnicore.vinhasoft.com.br";
//const coreBaseUri = "https://homolog.api.omnicore.vinhasoft.com.br";
//const coreBaseUri = "https://localhost:44392";

export const _config = {
    account:{
        baseUri: accountBaseUri + apiSegment + version1Segment,
        api: {
            authenticated: accountBaseUri + apiSegment + version1Segment + "/login/authenticated",
            login:{
                forgotPass: accountBaseUri + apiSegment + version1Segment + "/login/forgotpass",
                resetPass: accountBaseUri + apiSegment + version1Segment + "/login/resetpass",
            },
            affiliate:{
                index: accountBaseUri + apiSegment + version1Segment + "/affiliate",
                get: accountBaseUri + apiSegment + version1Segment + "/affiliate/{id}",
                insert: accountBaseUri + apiSegment + version1Segment + "/affiliate",                
                update: accountBaseUri + apiSegment + version1Segment + "/affiliate/{id}",
                delete: accountBaseUri + apiSegment + version1Segment + "/affiliate/{id}"
            },
            affiliateDomain:{
                index: accountBaseUri + apiSegment + version1Segment + "/affiliateDomain",
                insert: accountBaseUri + apiSegment + version1Segment + "/affiliateDomain",
                update: accountBaseUri + apiSegment + version1Segment + "/affiliateDomain/{id}",
                delete: accountBaseUri + apiSegment + version1Segment + "/affiliateDomain/{id}"
            },
            affiliateLogo:{
                index: accountBaseUri + apiSegment + version1Segment + "/affiliateLogo",
                insert: accountBaseUri + apiSegment + version1Segment + "/affiliateLogo",
                update: accountBaseUri + apiSegment + version1Segment + "/affiliateLogo/{id}",
                delete: accountBaseUri + apiSegment + version1Segment + "/affiliateLogo/{id}"
            },
            group:{
                index: accountBaseUri + apiSegment + version1Segment + "/groupcompany",
                get: accountBaseUri + apiSegment + version1Segment + "/groupcompany/{id}",
                insert: accountBaseUri + apiSegment + version1Segment + "/groupcompany",
                update: accountBaseUri + apiSegment + version1Segment + "/groupcompany/{id}",
                delete: accountBaseUri + apiSegment + version1Segment + "/groupcompany/{id}",
                createMissingEnvironment: accountBaseUri + apiSegment + version1Segment + "/groupcompany/{id}/createMissingEnvironment",
                updateEnvironmentStatus: accountBaseUri + apiSegment + version1Segment + "/groupcompany/{id}/updateEnvironmentStatus",
            },
            company:{
                index: accountBaseUri + apiSegment + version1Segment + "/company",
                insert: accountBaseUri + apiSegment + version1Segment + "/company",
                update: accountBaseUri + apiSegment + version1Segment + "/company/{id}",
                delete: accountBaseUri + apiSegment + version1Segment + "/company/{id}"
            },
            product:{
                index: accountBaseUri + apiSegment + version1Segment + "/product",
                get: accountBaseUri + apiSegment + version1Segment + "/product/{id}",
                insert: accountBaseUri + apiSegment + version1Segment + "/product",
                update: accountBaseUri + apiSegment + version1Segment + "/product/{id}",
                delete: accountBaseUri + apiSegment + version1Segment + "/product/{id}"
            },
            productModule:{
                index: accountBaseUri + apiSegment + version1Segment + "/productModule",
                get: accountBaseUri + apiSegment + version1Segment + "/productModule/{id}",
                insert: accountBaseUri + apiSegment + version1Segment + "/productModule",
                update: accountBaseUri + apiSegment + version1Segment + "/productModule/{id}",
                delete: accountBaseUri + apiSegment + version1Segment + "/productModule/{id}"
            },
            productVariation:{
                index: accountBaseUri + apiSegment + version1Segment + "/productVariation",
                get: accountBaseUri + apiSegment + version1Segment + "/productVariation/{id}",
                insert: accountBaseUri + apiSegment + version1Segment + "/productVariation",
                update: accountBaseUri + apiSegment + version1Segment + "/productVariation/{id}",
                delete: accountBaseUri + apiSegment + version1Segment + "/productVariation/{id}"
            },
            productVariationPrice:{
                index: accountBaseUri + apiSegment + version1Segment + "/productPrice",
                insert: accountBaseUri + apiSegment + version1Segment + "/productPrice",
                update: accountBaseUri + apiSegment + version1Segment + "/productPrice/{id}",
                delete: accountBaseUri + apiSegment + version1Segment + "/productPrice/{id}"
            },
            productFunctionality:{
                index: accountBaseUri + apiSegment + version1Segment + "/productFunctionality",
                insert: accountBaseUri + apiSegment + version1Segment + "/productFunctionality",
                update: accountBaseUri + apiSegment + version1Segment + "/productFunctionality/{id}",
                delete: accountBaseUri + apiSegment + version1Segment + "/productFunctionality/{id}"
            },
            billingPeriod:{
                index: accountBaseUri + apiSegment + version1Segment + "/billingPeriod",
                insert: accountBaseUri + apiSegment + version1Segment + "/billingPeriod",
                update: accountBaseUri + apiSegment + version1Segment + "/billingPeriod/{id}",
                delete: accountBaseUri + apiSegment + version1Segment + "/billingPeriod/{id}"
            },
            serviceRoute:{
                index: accountBaseUri + apiSegment + version1Segment + "/serviceRoute",
                get: accountBaseUri + apiSegment + version1Segment + "/serviceRoute/{id}",
                insert: accountBaseUri + apiSegment + version1Segment + "/serviceRoute",
                update: accountBaseUri + apiSegment + version1Segment + "/serviceRoute/{id}",
                delete: accountBaseUri + apiSegment + version1Segment + "/serviceRoute/{id}"
            },
            productRoute:{
                index: accountBaseUri + apiSegment + version1Segment + "/productRoute",
                get: accountBaseUri + apiSegment + version1Segment + "/productRoute/{id}",
                insert: accountBaseUri + apiSegment + version1Segment + "/productRoute",
                update: accountBaseUri + apiSegment + version1Segment + "/productRoute/{id}",
                delete: accountBaseUri + apiSegment + version1Segment + "/productRoute/{id}"
            },
            user:{
                index: accountBaseUri + apiSegment + version1Segment + "/user",
                get: accountBaseUri + apiSegment + version1Segment + "/user/{id}",
                insert: accountBaseUri + apiSegment + version1Segment + "/user",
                update: accountBaseUri + apiSegment + version1Segment + "/user/{id}",
                delete: accountBaseUri + apiSegment + version1Segment + "/user/{id}",
                changeUserPassword: accountBaseUri + apiSegment + version1Segment + "/user/{id}/changePassword",
                downloadUserFileToken: accountBaseUri + apiSegment + version1Segment + "/login/tokenfile",
                currentUserProfile: accountBaseUri + apiSegment + version1Segment + "/user/{id}/currentUserProfile",
                login: accountBaseUri + apiSegment + version1Segment + "/login",
            },
            userCompany:{
                index: accountBaseUri + apiSegment + version1Segment + "/userCompany",
                get: accountBaseUri + apiSegment + version1Segment + "/userCompany/{id}",
                insert: accountBaseUri + apiSegment + version1Segment + "/userCompany",
                update: accountBaseUri + apiSegment + version1Segment + "/userCompany/{id}",
                delete: accountBaseUri + apiSegment + version1Segment + "/userCompany/{id}"
            },
            userLicensePackage:{
                index: accountBaseUri + apiSegment + version1Segment + "/userLicensePackage",
                get: accountBaseUri + apiSegment + version1Segment + "/userLicensePackage/{id}",
                insert: accountBaseUri + apiSegment + version1Segment + "/userLicensePackage",
                update: accountBaseUri + apiSegment + version1Segment + "/userLicensePackage/{id}",
                delete: accountBaseUri + apiSegment + version1Segment + "/userLicensePackage/{id}"
            },
            license:{
                index: accountBaseUri + apiSegment + version1Segment + "/userLicensePackage",
                get: accountBaseUri + apiSegment + version1Segment + "/userLicensePackage/{id}",
                insert: accountBaseUri + apiSegment + version1Segment + "/userLicensePackage",
                update: accountBaseUri + apiSegment + version1Segment + "/userLicensePackage/{id}",
                delete: accountBaseUri + apiSegment + version1Segment + "/userLicensePackage/{id}"
            },
            userLicense:{
                index: accountBaseUri + apiSegment + version1Segment + "/userLicense",
                get: accountBaseUri + apiSegment + version1Segment + "/userLicense/{id}",
                insert: accountBaseUri + apiSegment + version1Segment + "/userLicense",
                update: accountBaseUri + apiSegment + version1Segment + "/userLicense/{id}",
                delete: accountBaseUri + apiSegment + version1Segment + "/userLicense/{id}",
                activeChange: accountBaseUri + apiSegment + version1Segment + "/userLicense/activeChange/{id}",
            },
            order:{
                index: accountBaseUri + apiSegment + version1Segment + "/order",
                get: accountBaseUri + apiSegment + version1Segment + "/order/{id}",
                insert: accountBaseUri + apiSegment + version1Segment + "/order",                
                update: accountBaseUri + apiSegment + version1Segment + "/order/{id}",
                delete: accountBaseUri + apiSegment + version1Segment + "/order/{id}",

                insertHeader: accountBaseUri + apiSegment + version1Segment + "/order/header",
                updateHeader: accountBaseUri + apiSegment + version1Segment + "/order/header/{id}",

                changeStatus: accountBaseUri + apiSegment + version1Segment + "/order/changeStatus/{id}",
            },
            orderItem:{
                index: accountBaseUri + apiSegment + version1Segment + "/orderItem",
                get: accountBaseUri + apiSegment + version1Segment + "/orderItem/{id}",
                insert: accountBaseUri + apiSegment + version1Segment + "/orderItem",                
                update: accountBaseUri + apiSegment + version1Segment + "/orderItem/{id}",
                delete: accountBaseUri + apiSegment + version1Segment + "/orderItem/{id}",
            },
            userPhoto:{
                avatar: accountBaseUri + apiSegment + version1Segment + "/userPhoto/{id}/asUri",
                upload: accountBaseUri + apiSegment + version1Segment + "/userPhoto/{id}/upload",
                delete: accountBaseUri + apiSegment + version1Segment + "/userPhoto/{id}",
            },
            policy:{
                index: accountBaseUri + apiSegment + version1Segment + "/policy",
                get: accountBaseUri + apiSegment + version1Segment + "/policy/{id}",
                insert: accountBaseUri + apiSegment + version1Segment + "/policy",                
                update: accountBaseUri + apiSegment + version1Segment + "/policy/{id}",
                delete: accountBaseUri + apiSegment + version1Segment + "/policy/{id}",
            },
            productPolicy:{
                index: accountBaseUri + apiSegment + version1Segment + "/productPolicy",
                get: accountBaseUri + apiSegment + version1Segment + "/productPolicy/{id}",
                insert: accountBaseUri + apiSegment + version1Segment + "/productPolicy",                
                update: accountBaseUri + apiSegment + version1Segment + "/productPolicy/{id}",
                delete: accountBaseUri + apiSegment + version1Segment + "/productPolicy/{id}",
            },
            productRequiredEnv:{
                index: accountBaseUri + apiSegment + version1Segment + "/productRequiredEnv",
                get: accountBaseUri + apiSegment + version1Segment + "/productRequiredEnv/{id}",
                insert: accountBaseUri + apiSegment + version1Segment + "/productRequiredEnv",                
                update: accountBaseUri + apiSegment + version1Segment + "/productRequiredEnv/{id}",
                delete: accountBaseUri + apiSegment + version1Segment + "/productRequiredEnv/{id}",
            },
            groupCompanyEnvironmentPendency:{
                index: accountBaseUri + apiSegment + version1Segment + "/groupCompanyEnvironmentPendency",
                get: accountBaseUri + apiSegment + version1Segment + "/groupCompanyEnvironmentPendency/{id}",
                insert: accountBaseUri + apiSegment + version1Segment + "/groupCompanyEnvironmentPendency",
                delete: accountBaseUri + apiSegment + version1Segment + "/groupCompanyEnvironmentPendency/{id}",
            },
            tokenInfo:{
                get: accountBaseUri + apiSegment + version1Segment + "/tokenInfo"
            }
        }
    },
    core:{
        baseUri: coreBaseUri,
        api: {
        
        }
    }
}