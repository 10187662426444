import { actionTypes } from '../actions/notify.action'

const INITIAL_STATE = {
    open: false,
    horizontal: 'center',
    vertical: 'top',
    class: 'error',
    time: 3000,
    msg: 'dados atualizados'
  };
  
  // eslint-disable-next-line
  export default (state = INITIAL_STATE, { type, payLoad }) => {
    switch (type) {
      case actionTypes.CHAGE:
        return { ...state, ...payLoad };
      default:
        return state;
    }
  };