import { httpAccount } from "../../config/http";
import { changeLoading } from "./loading.action";
import { changeNotify } from "./notify.action";
import Encrypt from "../../utils/Encript";
import Cookies from "universal-cookie";
import CookieDefs from "../../defs/cookieDefs";

const cookieDefs = CookieDefs();

const cookie = new Cookies()

export const actionTypes = {
  CHANGE: "AUTH_CHANGE",
  SUCCESS: "AUTH_SUCCESS",
};

export const change = (payload) => ({
  type: actionTypes.CHANGE,
  payload,
});

export const success = (payload) => ({
  type: actionTypes.SUCCESS,
  payload,
});
export const setUserToken = (token, userId, name, username, urlAvatar) => (dispatch) => {
  const tk = Encrypt(token);
  
  cookie.set(cookieDefs.ACCESS_TOKEN, tk)
  cookie.set(cookieDefs.USER_ID, userId);
  cookie.set(cookieDefs.USER_NAME, name);
  cookie.set(cookieDefs.USER_USERNAME, username);
  cookie.set(cookieDefs.USER_URI_AVATAR, urlAvatar);

  dispatch(
    change({
      email: "",
      password: "",
    })
  );
  dispatch(success(true));
};

export const login = (credentials) => (dispatch) => {
  dispatch(
    changeLoading({
      open: true,
      msg: "Autenticando...",
    })
  );

  return httpAccount
    .post("/login", {
      // grant_type: "password",
      // client_id: 2,
      // client_secret: "7NYfemhAyJpIPaEYfo3DADujxTSwkoPu9dwoygmL",
      username: credentials.email,
      password: credentials.password,
    })
    .then((res) => {
      dispatch(
        changeLoading({
          open: false,
        })
      );
      if (res.status === 200) {

        console.log(res.data);
        if (res.data.integrationUser === true)
        {
          dispatch(
            changeNotify({
              open: true,
              class: "error",
              time: 3000,
              msg: "Tipo de usuário integração não habilitado para uso desse aplicativo",
            })
          );

          cookie.remove(cookieDefs.ACCESS_TOKEN);
          cookie.remove(cookieDefs.USER_ID);
          cookie.remove(cookieDefs.USER_NAME);
          cookie.remove(cookieDefs.USER_USERNAME);
          cookie.remove(cookieDefs.USER_URI_AVATAR);

          window.location.href = "/login";
        }
        else
        {
          if (res.data.token) {
            dispatch(setUserToken(
                res.data.token, 
                res.data.id,
                res.data.name,
                res.data.username,
                res.data.urlAvatar,
            ));
          }
        }
      }
    })
    .catch((err) => {
      dispatch(
        changeLoading({
          open: false,
        })
      );
      if (typeof err.response !== "undefined") {
        if (err.response.status === 401 || err.response.status === 400) {
          dispatch(
            changeNotify({
              open: true,
              class: "error",
              time: 3000,
              msg: "E-mail ou Senha incorretos",
            })
          );
        }
      } else {
        dispatch(
          changeNotify({
            open: true,
            class: "error",
            time: 3000,
            msg: "Erro ao conectar com o Servidor",
          })
        );
      }
    });
};

export const Logout = (dispatch) => {
  dispatch(
    changeLoading({
      open: true,
      msg: "Saindo...",
    })
  );
  return httpAccount
    .post("/api/auth/logout")
    .then((res) => {
      dispatch(
        changeLoading({
          open: false,
          msg: "",
        })
      );
      cookie.remove(cookieDefs.ACCESS_TOKEN);
      cookie.remove(cookieDefs.USER_ID);
      cookie.remove(cookieDefs.USER_NAME);
      cookie.remove(cookieDefs.USER_USERNAME);
      cookie.remove(cookieDefs.USER_URI_AVATAR);

      window.location.href = "/login";
    })
    .catch((err) => {
        cookie.remove(cookieDefs.ACCESS_TOKEN);
        cookie.remove(cookieDefs.USER_ID);
        cookie.remove(cookieDefs.USER_NAME);
        cookie.remove(cookieDefs.USER_USERNAME);
        cookie.remove(cookieDefs.USER_URI_AVATAR);
        window.location.href = "/login";
    });
};

export const LogoutEx = () => {
  return httpAccount
    .post("/api/auth/logout")
    .then((res) => {      
        cookie.remove(cookieDefs.ACCESS_TOKEN);
        cookie.remove(cookieDefs.USER_ID);
        cookie.remove(cookieDefs.USER_NAME);
        cookie.remove(cookieDefs.USER_USERNAME);
        cookie.remove(cookieDefs.USER_URI_AVATAR);
        window.location.href = "/login";
    })
    .catch((err) => {
        cookie.remove(cookieDefs.ACCESS_TOKEN);
        cookie.remove(cookieDefs.USER_ID);
        cookie.remove(cookieDefs.USER_NAME);
        cookie.remove(cookieDefs.USER_USERNAME);
        cookie.remove(cookieDefs.USER_URI_AVATAR);
        window.location.href = "/login";
    })
}
